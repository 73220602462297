<template>
  <a-page-header
    @back="() => $router.go(-1)"
    title="GESTANTES CON PAQUETE PREVENTIVO COMPLETO"
    sub-title="Distritos Q1yQ2"
    class="pt-0 px-0"
  >
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Estadística</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Paquete preventivo gestante</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <a-form-item label="PERIODO" class="m-0 p-0 w-48">
    <a-select @select="onSelectYear" v-model:value="yearEvaluacion">
      <a-select-option v-for="y in years" :value="y" :key="y">
        {{ y }}
      </a-select-option>
    </a-select>
  </a-form-item>

  <PaquetePreventivo :escalaColores="escalaColores" :optionsGrafico="optionsGrafico" />
</template>

<script>
import PaquetePreventivo from "@/components/PaquetePreventivo.vue";
import EstadisticoApi from "@/api/estadistico";
import {
  generaGraficosLine,
  optionGraphic,
  generaLeyendaPaquete
} from "@/components/utilsPaquetePreventivo";
import { ref, onMounted } from "vue";
import moment from "moment";

export default {
  components: {
    PaquetePreventivo
  },
  setup() {
    var anio = moment(new Date()).local().format("YYYY");

    var oldanios = [];
    for (let index = 0; index < 4; index++) {
      oldanios.push(anio - index);
    }

    const years = ref(oldanios);
    const yearEvaluacion = ref(years.value[1]);

    const optionsGrafico = ref(null);

    const escalaColores = ref([]);

    const fetchDataPage = () => {
      EstadisticoApi.paquetePreventivoMC1FiltroAnio(yearEvaluacion.value)
        .then((response) => {
          console.log("response", response);
          const valoresGraficas = generaGraficosLine(response.data);
          optionsGrafico.value = optionGraphic("", valoresGraficas);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          escalaColores.value = generaLeyendaPaquete();
        });
    };

    const onSelectYear = () => {
      fetchDataPage();
    };

    onMounted(() => {
      fetchDataPage();
    });

    return {
      years,
      escalaColores,
      yearEvaluacion,
      optionsGrafico,
      onSelectYear
    };
  }
};
</script>

<style></style>
